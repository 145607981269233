var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overview-card-list" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 30 } },
        _vm._l(_vm.dataList, function (item, index) {
          return _c("el-col", { key: index, attrs: { span: 6 } }, [
            _c("div", { staticClass: "single-card" }, [
              _c("div", { staticClass: "left-part" }, [
                _c("img", {
                  attrs: {
                    src: require(`@/assets/images/rdata/overview_${item.type}_icon.png`),
                    alt: "",
                  },
                }),
              ]),
              _c("div", { staticClass: "right-part" }, [
                _c("div", { staticClass: "label-line" }, [
                  _vm._v(
                    "\n            " + _vm._s(item.label) + "\n          "
                  ),
                ]),
                _c("div", { staticClass: "value-line" }, [
                  _c("span", { staticClass: "value-span" }, [
                    _vm._v(
                      "\n              " + _vm._s(item.value) + "\n            "
                    ),
                  ]),
                  item.unit
                    ? _c("span", { staticClass: "unit-span" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.unit) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }