<template>
  <div class="app-container comprehensive-analysis-board">
    <div class="main-title" style="margin-top: 10px">
      <img
        src="@/assets/images/rdata/comprehensive_board_analysis.png"
        alt=""
      />
      <span class="title-span"> 数据分析 </span>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <!-- 近15天产生数据总量趋势(万条) -->
          <RecentlyTotalTrend />
        </el-col>
        <el-col :span="8">
          <!-- 近15天各期数据量占比(万条) -->
          <RecentlyDataPropertion />
        </el-col>
      </el-row>
      <el-row :gutter="30" style="margin-top: 30px">
        <el-col :span="16">
          <!-- 近15天各期产生数据量趋势(万条) -->
          <RecentlyItemsTrend />
        </el-col>
        <el-col :span="8">
          <!-- 近15天各期数据量(万条) -->
          <RecentlyItemsDatas />
        </el-col>
      </el-row>
    </div>
    <div class="main-title">
      <img
        src="@/assets/images/rdata/comprehensive_board_overview.png"
        alt=""
      />
      <span class="title-span"> 项目总览 </span>
    </div>
    <div class="card-container">
      <OverviewCardList />
      <!-- 项目各系统总览 -->
      <ProjectItemsOverview />
    </div>
    <div class="main-title">
      <img src="@/assets/images/rdata/comprehensive_board_alarm.png" alt="" />
      <span class="title-span"> 告警情况 </span>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="16">
          <!-- 近15天告警数量趋势(条) -->
          <RecentlyAlarmTrend />
        </el-col>
        <el-col :span="8">
          <!-- 各级类型告警情况 -->
          <CatelogAlarmSituation />
        </el-col>
      </el-row>
      <el-row :gutter="30" style="margin-top: 30px">
        <el-col :span="16">
          <!-- 近15天故障变化趋势 -->
          <RecentlyErrorTrend />
        </el-col>
        <el-col :span="8">
          <!-- 各类型告警占比 -->
          <CatelogAlarmPropertion />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import RecentlyTotalTrend from "./components/recentlyTotalTrend.vue";
import RecentlyDataPropertion from "./components/recentlyDataPropertion.vue";
import RecentlyItemsTrend from "./components/recentlyItemsTrend.vue";
import RecentlyItemsDatas from "./components/recentlyItemsDatas.vue";
import OverviewCardList from "./components/overviewCardList.vue";
import ProjectItemsOverview from "./components/projectItemsOverview.vue";
import RecentlyAlarmTrend from "./components/recentlyAlarmTrend.vue";
import CatelogAlarmSituation from "./components/catelogAlarmSituation.vue";
import RecentlyErrorTrend from "./components/recentlyErrorTrend.vue";
import CatelogAlarmPropertion from "./components/catelogAlarmPropertion.vue";
export default {
  name: "comprehensiveAnalysisBoard",
  components: {
    RecentlyTotalTrend,
    RecentlyDataPropertion,
    RecentlyItemsTrend,
    RecentlyItemsDatas,
    OverviewCardList,
    ProjectItemsOverview,
    RecentlyAlarmTrend,
    CatelogAlarmSituation,
    RecentlyErrorTrend,
    CatelogAlarmPropertion,
  },
};
</script>

<style lang="less" scoped>
.comprehensive-analysis-board {
  margin: 10px 20px 20px 40px;
  background: none;
  padding-left: 0;
  .main-title {
    margin: 30px 0 20px;
    line-height: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    .title-span {
      font-size: 18px;
      font-family: Source Han Sans SC-Bold, Source Han Sans SC;
      font-weight: bold;
      color: #152c5b;
    }
    img {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
  }
  .card-container {
    margin-top: 20px;
  }
}
</style>