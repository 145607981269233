var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container comprehensive-analysis-board" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("RecentlyTotalTrend")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [_c("RecentlyDataPropertion")],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "30px" }, attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("RecentlyItemsTrend")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [_c("RecentlyItemsDatas")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "card-container" },
        [_c("OverviewCardList"), _c("ProjectItemsOverview")],
        1
      ),
      _vm._m(2),
      _c(
        "div",
        { staticClass: "card-container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("RecentlyAlarmTrend")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [_c("CatelogAlarmSituation")],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "30px" }, attrs: { gutter: 30 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("RecentlyErrorTrend")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [_c("CatelogAlarmPropertion")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "main-title", staticStyle: { "margin-top": "10px" } },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/rdata/comprehensive_board_analysis.png"),
            alt: "",
          },
        }),
        _c("span", { staticClass: "title-span" }, [_vm._v(" 数据分析 ")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/rdata/comprehensive_board_overview.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-span" }, [_vm._v(" 项目总览 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/rdata/comprehensive_board_alarm.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-span" }, [_vm._v(" 告警情况 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }