var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-item-overview" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "项目各系统总览" } }),
      _c("div", { staticClass: "table-container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "table-line" },
          _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "single-line",
                style: {
                  background: index % 2 === 1 ? "#f4f7f9" : "#ffffff",
                  borderRadius: index % 2 === 1 ? "10px" : "0",
                },
              },
              [
                _c("span", { staticClass: "single-item" }, [
                  _vm._v(_vm._s(index + 1)),
                ]),
                _c("span", { staticClass: "single-item" }, [
                  _vm._v(_vm._s(item.name1)),
                ]),
                _c("span", { staticClass: "single-item" }, [
                  _vm._v(_vm._s(item.name2)),
                ]),
                _c("span", { staticClass: "single-item" }, [
                  _vm._v(_vm._s(item.name3)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "table-title" }, [
      _c("span", { staticClass: "single-title" }, [_vm._v("序号")]),
      _c("span", { staticClass: "single-title" }, [_vm._v("锦绣一期")]),
      _c("span", { staticClass: "single-title" }, [_vm._v("锦绣二期")]),
      _c("span", { staticClass: "single-title" }, [_vm._v("锦绣三期")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }