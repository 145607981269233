var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recently-alarm-trend" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "近15天告警数量趋势(条)" } }),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "recentlyAlarmTrend" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }