<template>
  <div class="project-item-overview">
    <WaterEnergyTitle title="项目各系统总览" />
    <div class="table-container">
      <div class="table-title">
        <span class="single-title">序号</span>
        <span class="single-title">锦绣一期</span>
        <span class="single-title">锦绣二期</span>
        <span class="single-title">锦绣三期</span>
      </div>
      <div class="table-line">
        <div
          class="single-line"
          v-for="(item, index) in dataList"
          :key="index"
          :style="{
            background: index % 2 === 1 ? '#f4f7f9' : '#ffffff',
            borderRadius: index % 2 === 1 ? '10px' : '0',
          }"
        >
          <span class="single-item">{{ index + 1 }}</span>
          <span class="single-item">{{ item.name1 }}</span>
          <span class="single-item">{{ item.name2 }}</span>
          <span class="single-item">{{ item.name3 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
export default {
  name: "projectItemOverview",
  components: {
    WaterEnergyTitle,
  },
  data() {
    return {
      dataList: [
        {
          name1: "视频监控",
          name2: "视频监控",
          name3: "视频监控",
        },
        {
          name1: "电表",
          name2: "电表",
          name3: "电表",
        },
        {
          name1: "水表",
          name2: "水表",
          name3: "水表",
        },
        {
          name1: "冷量表",
          name2: "冷量表",
          name3: "冷量表",
        },
        {
          name1: "中水",
          name2: "中水",
          name3: "中水",
        },
        {
          name1: "水液压",
          name2: "水液压",
          name3: "水液压",
        },
        {
          name1: "水液压",
          name2: "水液压",
          name3: "水液压",
        },
        {
          name1: "水液压",
          name2: "水液压",
          name3: "水液压",
        },
        {
          name1: "水液压",
          name2: "水液压",
          name3: "水液压",
        },
        {
          name1: "水液压",
          name2: "水液压",
          name3: "水液压",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.project-item-overview {
  margin-top: 30px;
  height: 560px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .table-container {
    height: 480px;
    margin-top: 20px;
    .table-title {
      height: 40px;
      line-height: 40px;
      background: #f4f7f9;
      border-radius: 10px;
      display: flex;
      .single-title {
        flex: 1;
        font-size: 14px;
        font-family: Source Han Sans SC-Regular, Source Han Sans SC;
        font-weight: 400;
        color: #8190ac;
        text-align: center;
      }
    }
    .table-line {
      height: 450px;
      overflow: auto;
      .single-line {
        height: 56px;
        line-height: 56px;
        display: flex;
        .single-item {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}
</style>