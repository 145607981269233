<template>
  <div class="overview-card-list">
    <el-row :gutter="30">
      <el-col :span="6" v-for="(item, index) in dataList" :key="index">
        <div class="single-card">
          <div class="left-part">
            <img
              :src="
                require(`@/assets/images/rdata/overview_${item.type}_icon.png`)
              "
              alt=""
            />
          </div>
          <div class="right-part">
            <div class="label-line">
              {{ item.label }}
            </div>
            <div class="value-line">
              <span class="value-span">
                {{ item.value }}
              </span>
              <span v-if="item.unit" class="unit-span">
                {{ item.unit }}
              </span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "overviewCardList",
  data() {
    return {
      dataList: [
        {
          label: "接入设备总数",
          value: 283766,
          type: "accessDevice",
        },
        {
          label: "点位接入总数",
          value: 283766,
          type: "accessPoint",
        },
        {
          label: "在线设备数",
          value: 283766,
          type: "onlineDevice",
        },
        {
          label: "设备在线率",
          value: 283766,
          type: "onlineRate",
          unit: "%",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.overview-card-list {
  .single-card {
    height: 100px;
    background: #ffffff;
    border-radius: 20px;
    padding: 22px 18px;
    display: flex;
    .right-part {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label-line {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3aed0;
        line-height: 14px;
      }
      .value-line {
        height: 28px;
        font-size: 28px;
        font-family: Helvetica-Bold, Helvetica;
        color: #152c5b;
        line-height: 28px;
        .value-span {
          font-weight: bold;
          display: inline-block;
        }
        .unit-span {
          font-weight: bold;
          font-size: 0.6em;
        }
      }
    }
  }
}
</style>